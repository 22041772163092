.szh-accordion {
  border-bottom: 1px solid #ccc;

  .quick-tips-icon {
    margin-left: auto;
    margin-right: 10px;
    height: 40px;

    img {
      height: 40px;
    }
  }

  &__item {
    border-top: 1px solid #ccc;

    &-btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0;
      padding: 0.75rem 1rem;
      font-size: 1rem;
      font-weight: 400;
      text-align: left;
      background-color: transparent;
      border: none;
      &:hover {
        background-color: #f3f3f3;
      }
    }

    &-content {
      transition: height 0.25s cubic-bezier(0, 0, 0, 1);
    }

    &-panel {
      padding: 1rem;
    }

    &.section-invalid {
      .szh-accordion__item-btn {
        color: red;

        .item-header {
          display: flex;

          &::after {
            margin-left: 10px;
            content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Ccircle cx='10' cy='10' r='9' stroke='red' stroke-width='1' fill='none' /%3E%3Ctext x='10' y='13' font-size='12' font-weight='bold' text-anchor='middle' fill='red'%3E!%3C/text%3E%3C/svg%3E");
            display: inline-block;
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &.section-unverified {
      .szh-accordion__item-btn {
        .item-header {
          display: flex;

          &::after {
            margin-left: 10px;
            content: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><circle cx='50' cy='50' r='45' fill='none' stroke='%23888' stroke-width='6' /><text x='50' y='68' font-size='60' font-family='Arial, sans-serif' fill='%23888' text-anchor='middle'>?</text></svg>");
            display: inline-block;
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &.section-unopened {
      .szh-accordion__item-btn {
        .item-header {
          display: flex;

          &::after {
            margin-left: 10px;
            content: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><circle cx='50' cy='50' r='45' fill='none' stroke='%23888' stroke-width='6' /><text x='50' y='68' font-size='60' font-family='Arial, sans-serif' fill='%23888' text-anchor='middle'>?</text></svg>");

            display: inline-block;
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &.section-valid {
      .szh-accordion__item-btn {
        .item-header {
          display: flex;

          &::after {
            margin-left: 10px;
            content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Ccircle cx='50' cy='50' r='45' stroke='green' stroke-width='5' fill='none' /%3E%3Cpath d='M30 50 L45 65 L75 35' stroke='green' stroke-width='8' fill='none' /%3E%3C/svg%3E");
            display: inline-block;
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &--expanded {
      .szh-accordion__item-btn {
        background-color: #e7e7e7;
      }
      .chevron-down {
        transform: rotate(180deg);
      }
    }

    .chevron-down {
      margin-left: auto;

      &.with-tips {
        margin-left: unset;
      }

      transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
    }
  }
}
